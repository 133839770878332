var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('s-crud',{attrs:{"filter":_vm.filter,"config":_vm.configChamber,"add":"","edit":"","remove":"","title":"Cámaras","height":"auto"},on:{"save":function($event){return _vm.save($event)},"selected":function($event){return _vm.selectedCha($event)}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-col',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('s-text',{attrs:{"label":"Descripción"},model:{value:(props.item.ChaDescription),callback:function ($$v) {_vm.$set(props.item, "ChaDescription", $$v)},expression:"props.item.ChaDescription"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('s-select-definition',{attrs:{"def":1181,"label":"Destino"},model:{value:(props.item.TypeFreshProductionDestination),callback:function ($$v) {_vm.$set(props.item, "TypeFreshProductionDestination", $$v)},expression:"props.item.TypeFreshProductionDestination"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('s-select-definition',{attrs:{"def":1347,"label":"Tipo"},model:{value:(
									props.item
										.TypeChamberTunnel
								),callback:function ($$v) {_vm.$set(props.item
										, "TypeChamberTunnel", $$v)},expression:"\n\t\t\t\t\t\t\t\t\tprops.item\n\t\t\t\t\t\t\t\t\t\t.TypeChamberTunnel\n\t\t\t\t\t\t\t\t"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('s-select-definition',{attrs:{"def":1282,"label":"Estado"},model:{value:(props.item.ChaStatus),callback:function ($$v) {_vm.$set(props.item, "ChaStatus", $$v)},expression:"props.item.ChaStatus"}})],1)],1)],1)]}},{key:"Positions",fn:function({ row }){return [_c('v-btn',{attrs:{"color":"success","x-small":"","rounded":""},on:{"click":function($event){return _vm.clickPositions(row)}}},[_vm._v(" +")])]}},{key:"ChaRows",fn:function({ row }){return [_c('v-btn',{attrs:{"color":"primary","x-small":"","rounded":""},on:{"click":function($event){return _vm.clickChaRows(row)}}},[_vm._v(" +")])]}},{key:"ChaCol",fn:function({ row }){return [_c('v-btn',{attrs:{"color":"warning","x-small":"","rounded":""},on:{"click":function($event){return _vm.clickChaCol(row)}}},[_vm._v(" +")])]}}])}),(_vm.openModal)?_c('v-dialog',{attrs:{"width":"700","persistent":""},model:{value:(_vm.openModal),callback:function ($$v) {_vm.openModal=$$v},expression:"openModal"}},[_c('v-card',[_c('s-toolbar',{attrs:{"close":"","label":'Carriles -' + _vm.itemSelected.ChaDescription + '-',"dark":"","color":"#8e8f91"},on:{"close":function($event){_vm.openModal = false}}}),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('s-crud',{attrs:{"filter":_vm.filterChamberPos,"config":_vm.configChamberPos,"add":"","edit":"","remove":"","noFooter":"","noSearch":""},on:{"save":function($event){return _vm.savePosition($event)}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',{staticStyle:{"margin":"auto"}},[_c('v-col',{staticClass:"pt-0",attrs:{"lg":"4","cols":"12"}},[_c('s-text',{attrs:{"number":"","min":0,"label":"Posición"},model:{value:(
													props.item.ChpPosition
												),callback:function ($$v) {_vm.$set(props.item, "ChpPosition", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\tprops.item.ChpPosition\n\t\t\t\t\t\t\t\t\t\t\t\t"}})],1)],1)]}}],null,false,3179937495)})],1)],1)],1)],1)],1):_vm._e(),(_vm.openModalRows)?_c('v-dialog',{attrs:{"width":"700","persistent":""},model:{value:(_vm.openModalRows),callback:function ($$v) {_vm.openModalRows=$$v},expression:"openModalRows"}},[_c('v-card',[_c('s-toolbar',{attrs:{"dark":"","color":"#8e8f91","close":""},on:{"close":function($event){_vm.openModalRows = false}}}),_c('chamber-rows',{attrs:{"ChaID":this.itemSelected.ChaID}})],1)],1):_vm._e(),(_vm.openModalColum)?_c('v-dialog',{attrs:{"width":"700","persistent":""},model:{value:(_vm.openModalColum),callback:function ($$v) {_vm.openModalColum=$$v},expression:"openModalColum"}},[_c('v-card',[_c('s-toolbar',{attrs:{"dark":"","color":"#8e8f91","close":""},on:{"close":function($event){_vm.openModalColum = false}}}),_c('chamber-column',{attrs:{"ChaID":this.itemSelected.ChaID}})],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }